<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="vehicles"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center justify-end flex-grow"
        >
          <div class="flex">
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
            <div class="flex flex-end">
              <vs-input
                icon-pack="feather"
                icon="icon-search"
                placeholder="Search"
                v-model="dataTableParams.search"
                class="w-full"
              />
            </div>
          </div>
        </div>
        <template slot="thead">
          <vs-th sort-key="lotNumber">Lot #</vs-th>
          <!-- <vs-th>Auction Status</vs-th> -->
          <vs-th sort-key="createdAt">Created</vs-th>
          <vs-th sort-key="approvalTime">Approved</vs-th>
          <vs-th sort-key="auctionEndDate">Ended</vs-th>
          <vs-th>Car details</vs-th>
          <vs-th>Car Image</vs-th>
          <vs-th sort-key="lastBid">Last Bid</vs-th>
          <vs-th sort-key="bidCount">Bids</vs-th>
          <vs-th sort-key="reservePrice">Reserve</vs-th>
          <vs-th sort-key="reserveStatus">Reserve Met</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td :data="data[indextr]._id">{{
                            data[indextr].dealerId ? data[indextr].dealerId.name :
                                (data[indextr].userId.fullName ? data[indextr].userId.fullName :
                                    data[indextr].userId.firstName)
                        }}</vs-td> -->

            <!-- <vs-td class="td vs-table--td" :data="data[indextr].mainImage">
                            <div class="img-container">
                                <div class="image">
                                    <img @click="viewDetailHandler(data[indextr]._id)" :src="data[indextr].mainImage" />
                                </div>
                            </div>
                        </vs-td> -->
            <vs-td :data="data[indextr].lotNumber">{{
              data[indextr].lotNumber
            }}</vs-td>
            <!-- <vs-td :data="data[indextr].status">{{ formatStatus(data[indextr].status) }}</vs-td> -->
            <vs-td :data="data[indextr].createdAt">{{
              getFormatedDate(data[indextr].createdAt)
            }}</vs-td>
            <vs-td :data="data[indextr].approvalTime">{{
              getFormatedDate(data[indextr].approvalTime)
            }}</vs-td>
            <vs-td :data="data[indextr].auctionEndDate">{{
              getFormatedDate(data[indextr].auctionEndDate)
            }}</vs-td>
            <vs-td :data="data[indextr].make">{{
              data[indextr].car.make +
              " " +
              data[indextr].car.model +
              " " +
              data[indextr].car.year
            }}</vs-td>
            <vs-td class="td vs-table--td" :data="data[indextr].carImage">
              <div class="img-container">
                <div class="image">
                  <img
                    @click="viewDetailHandler(data[indextr]._id)"
                    :src="
                      data[indextr].carImage.length > 0
                        ? data[indextr].carImage
                        : noImageStaticUrl
                    "
                  />
                </div>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].lastBid">{{
              data[indextr].lastBid
                ? "$" +
                  new Intl.NumberFormat("en-AU").format(data[indextr].lastBid)
                : "N/A"
            }}</vs-td>
            <vs-td :data="data[indextr].bidCount">
              <span @click="goToBiddingHistoryTab(data[indextr]._id)">{{
                data[indextr].bidCount ? data[indextr].bidCount : 0
              }}</span></vs-td
            >
            <vs-td :data="data[indextr].reservePrice"
              >${{
                new Intl.NumberFormat("en-AU").format(
                  data[indextr].reservePrice
                )
              }}</vs-td
            >
            <vs-td :data="data[indextr].status">{{
              checkReserveMet(data[indextr].lastBid, data[indextr].reservePrice)
            }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      noImageStaticUrl:
        "https://caroo-bucket.s3-ap-southeast-2.amazonaws.com/assets/noCarImage.png",
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
        filter: "",
        status: "completed",
      },
      vehicles: [],
      limitOptions: [5, 10, 25, 50, 100],
      filterOptions: [
        "All",
        "Private (all)",
        "Private (classified)",
        "Private (auction)",
        "Dealership",
      ],
      selectedCar: {},

      fileName: "",
      cellAutoWidth: true,
      selectedFormat: "csv",
      headerTitle: [
        "Id",
        "Registration Upto Date",
        "Full Service Available",
        "Has Written Off",
        "Seller Type",
        "Allow Sms",
        "Allow Call",
        "Parked",
        "Listed",
        "Drafted",
        "Licence Plate",
        "Year",
        "Transmission",
        "Transmission Type",
        "Engine Capacity",
        "Cylinder",
        "Registration Expiry Date",
        "Registration State",
        "Body Type",
        "Variant",
        "Series",
        "Vin Number",
        "Make",
        "Exterior Color",
        "Interior Color",
        "Drive Type",
        "Model",
        "Created At",
        "Updated At",
        "Asking Price",
        "Price Type",
        "Description",
        "Kilo Meter",
        "Contact Name",
        "Contact Number",
        "Display Address",
        "Email",
        "Mailing Address",
        "Owner Email",
        "Owner Name",
        "Stock Number",
        "DealerID",
      ],
      headerVal: [
        "_id",
        "isRegistrationUptoDate",
        "fullServiceAvailable",
        "hasWrittenOff",
        "sellerType",
        "allowSms",
        "allowCall",
        "isParked",
        "isListed",
        "isDrafted",
        "licencePlate",
        "year",
        "transmission",
        "transmissionType",
        "engineCapacity",
        "cylinder",
        "registrationExpiryDate",
        "registrationState",
        "bodyType",
        "badge",
        "series",
        "vinNumber",
        "make",
        "exteriorColor",
        "interiorColor",
        "drv",
        "model",
        "createdAt",
        "updatedAt",
        "askingPrice",
        "priceTag",
        "description",
        "kilometer",
        "contactName",
        "contactNumber",
        "displayAddress",
        "email",
        "mailingAddress",
        "ownerEmail",
        "ownerName",
        "stockNumber",
        "dealerId",
      ],
      selectedVehiclesForCsv: [],
      activePrompt: false,
    };
  },
  methods: {
    ...mapActions("admin", [
      "fetchVehicles",
      "fetchAuctions",
      "fetchVehiclesForCsv",
      "deleteCar",
    ]),
    ...mapActions([
      "updateVehiclePage",
      "initToFirstPage",
      "updateVehiclePageLimit",
      "updateVehiclePageFilter",
      "updateVehiclePageSearch",
    ]),
    moment(date) {
      return moment(date);
    },
    getAuctionsList() {
      let self = this;
      this.fetchAuctions(self.dataTableParams).then((res) => {
        self.vehicles = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getAuctionsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAuctionsList();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "AuctionDetails", params: { id: id } });
    },
    editDetailHandler(carId) {
      this.$router.push({ name: "EditVehicle", params: { carId: carId } });
    },

    confirmDeleteRecord(data, i) {
      this.selectedCar = data;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${data.make}"`,
        accept: this.deleteCarDetail,
        acceptText: "Delete",
      });
    },

    deleteCarDetail() {
      this.deleteCar(this.selectedCar._id).then((res) => {
        this.$vs.notify({
          title: "Vehicle Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.vehicles.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          if (j === "images") {
            // v[j] = v[j].replace("http", "\nhttp");
            return v[j];
            // return parseTime(v[j]);
          } else if (j === "createdAt") {
            v[j] = moment(v[j]).format("DD/MM/YYYY");
            return v[j];
          } else if (j === "updatedAt") {
            v[j] = moment(v[j]).format("DD/MM/YYYY");
            return v[j];
          } else if (j === "registrationExpiryDate") {
            if (v[j] && v[j] !== "Refer Seller") {
              v[j] = moment(v[j]).format("DD/MM/YYYY");
            } else {
              v[j] = "Refer Seller";
            }
            return v[j];
          } else {
            return v[j] ? v[j] : "Refer Seller";
          }
          // return v[j];
        })
      );
    },
    clearFields() {
      this.fileName = "";
      this.cellAutoWidth = true;
      this.selectedFormat = "csv";
    },
    checkSellerType(type, isAuction) {
      if (type === "dealership") {
        return "Dealer";
      } else {
        if (isAuction == true) {
          return "Private (auction)";
        } else {
          return "Private (classified)";
        }
      }
    },
    getFormatedDate(date) {
      let temp = new Date(date);
      let day = temp.getDate();
      let month = temp.getMonth() + 1;
      let year = temp.getFullYear();
      let dateOnly = year + "-" + month + "-" + day;
      let hour = temp.getHours();
      let minutes = temp.getMinutes();
      let amPm = hour < 12 ? "AM" : "PM";
      hour = hour % 12;
      hour = hour ? hour : 12;
      let time = `${hour}:${minutes < 10 ? "0" + minutes : minutes} ${amPm}`;
      return dateOnly + " " + time;
    },
    formatImage(images) {
      return "Refer Seller";
    },
    formatStatus(status) {
      if (status === "live") return "Live";
    },
    checkReserveMet(lastBid = 0, ReservePrice) {
      if (lastBid >= ReservePrice) return "Met";
      return "Not met";
    },
    goToBiddingHistoryTab(id) {
      this.$router.push({
        name: "AuctionDetails",
        params: { id: id },
        query: { activeTab: 2 },
      });
    },
  },
  filters: {
    // Filter definitions
    capitalize(string) {
      return string.charAt(0).toString().toUpperCase() + string.slice(1);
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateVehiclePage(newVal);
        this.dataTableParams.page = newVal;
        this.getAuctionsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateVehiclePageLimit(newlimit);
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getAuctionsList();
      }
    },
    "dataTableParams.filter": function (newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        this.updateVehiclePageFilter(newFilter);
        // this.dataTableParams.page = 1;
        this.dataTableParams.filter = newFilter;
        this.getAuctionsList();
      }
    },
    "dataTableParams.search": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = 1;
        this.updateVehiclePageSearch(newVal);
        this.dataTableParams.search = newVal;
        this.getAuctionsList();
      }
    },
  },
  created() {
    this.initToFirstPage({ pageType: "vehicle" });
    this.getAuctionsList();
  },
};
</script>
<style>
.img-container {
  /* border: 1px solid #ccc; */
  float: left;
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
}

.image img {
  max-width: 150px;
  max-height: 100px;
  height: auto;
  object-fit: contain;
}

.image {
  display: flex;
  align-self: center;
}
</style>
